

ion-grid{
    height: 100%;
}
ion-row{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

ion-button {
    cursor: pointer;
}

ion-content.background{
    --background: #000 url('../assets/background.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}


/* 
.google-login-btn {
    width: 165px;
    height:35px;
    border-radius: 4px;
    background: #db3236;
    color:white;
    border:0px transparent;
    text-align: center;
}

.google-login-btn:hover {
    background: #3b5998;
    opacity: 0.6;
} */


.grad {
    background: linear-gradient(
      to bottom,
      black,
      rgba(255, 0, 0, 0)
    )
  }

.race-left {
    position: absolute;
    left: -400px;
    top: -30px;
    height: 843px;
    width: 843px;
}

.race-right {
    position: absolute;
    right: -264px;
    top: 90px;
    height: 534px;
    width: 529px;
}


@media screen and (max-width: 768px) {

    .race-left {
        display: none;
    }
    
    .race-right {
        display: none;
    }
}