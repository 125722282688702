.login-modal {
    --background: url('../assets/modal-bg.jpg') no-repeat center 0 #091325;
  }

/* .login-modal {
  --background: #021506;
} */

.login-modal .modal-wrapper {
    height: 400px;
    width: 400px;
    /* top: 80%; */
    position: absolute; 
    display: block;  
    border: 5px solid rgba(0, 0, 0, 1);
}


.fb-login-btn {
  width: 250px;
  height:40px;  
  border-radius: 4px;
  background: #3b5998;
  color:white;
  border:0px transparent;  
  margin:5px;
  display: inline-block;
  direction: rtl;
  font-size: 19px;
}

.fb-login-btn:hover {
  background: #3b5998;
  opacity: 0.9;
  cursor:pointer;
}



a.regular-login {
  color: white;
  text-decoration: none;
}

a.regular-login:hover {
  text-decoration: underline;
}

i.fa.fa-facebook {
  text-align: right;
  position:relative;
  right: -30px;
  font-size: 20px;
}

.header-grad {
  background: linear-gradient(
      to bottom,
      rgb(0, 0, 0),
      rgba(0, 92, 12, 0)
  )
}

