#footer {
    position: fixed;
    bottom: 0%;
    width: 100%;
    justify-content : center;
}


.myfooter {
    --background: #021506 linear-gradient(
        to bottom,
        rgb(0, 43, 6),
        rgba(0, 92, 12, 0)
    );

    color: #02bb00;
}

  
a.footer-link {
    color: rgb(173, 173, 173);
    text-decoration: none;
    padding-left: 10px;
    font-size: 10pt;
  }
  
  a.footer-link:hover {
    text-decoration: underline;
  }