.itable, .stable{
	width: 100%;
	color: #c69b6d;
}

.itable td , .stable td{padding: 8px; }
.itable tr, .stable tr{border-bottom: 1px solid #30190b}

.itable tr:nth-child(even) , .stable tr:nth-child(even){background: #241106;}
.itable tr:nth-child(odd)  , .stable tr:nth-child(odd){background: #2b1507;}

.itable tr:first-child, .stable tr:first-child{
	color: white;
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #5c1f01), color-stop(1, #6a1908));
	background:-moz-linear-gradient(top, #5c1f01 5%, #6a1908 100%);
	background:-webkit-linear-gradient(top, #5c1f01 5%, #6a1908 100%);
	background:-o-linear-gradient(top, #5c1f01 5%, #6a1908 100%);
	background:-ms-linear-gradient(top, #5c1f01 5%, #6a1908 100%);
	background:linear-gradient(to bottom, #5c1f01 5%, #6a1908 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#5c1f01', endColorstr='#6a1908',GradientType=0);
}

.itable tr:first-child td, .stable tr:first-child td{
border-left: 1px solid #391303;
border-right: 1px solid #712504;
}


.itable tr:hover{background: #47250f}
.itable tr:first-child:hover, .stable tr:first-child:hover {
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #5c1f01), color-stop(1, #6a1908));
	background:-moz-linear-gradient(top, #5c1f01 5%, #6a1908 100%);
	background:-webkit-linear-gradient(top, #5c1f01 5%, #6a1908 100%);
	background:-o-linear-gradient(top, #5c1f01 5%, #6a1908 100%);
	background:-ms-linear-gradient(top, #5c1f01 5%, #6a1908 100%);
	background:linear-gradient(to bottom, #5c1f01 5%, #6a1908 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#5c1f01', endColorstr='#6a1908',GradientType=0);
}


table tr:first-child td:first-child{
	 -moz-border-radius: 0 5px 0 0;
    -webkit-border-radius: 0 5px 0 0;
    border-radius: 0 5px 0 0;
	border: 0px;
}
table tr:first-child td:last-child{
	 -moz-border-radius: 5px 0 0 0;
    -webkit-border-radius: 5px 0 0 0;
    border-radius: 5px 0 0 0;
	border: 0px;
}
table tr:last-child td:first-child{
	 -moz-border-radius: 0 0 5px 0;
    -webkit-border-radius: 0 0 5px 0;
    border-radius: 0 0 5px 0;
	border: 0px;
}
table tr:last-child td:last-child{
	 -moz-border-radius: 0px 0 0 5px;
    -webkit-border-radius: 0px 0 0 5px;
    border-radius: 0px 0 0 5px;
	border: 0px;
}

table tr:last-child{
	border: 0px;
}